import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/base/Layout';
import Seo from '../components/base/Seo';
import BenefitSection from '../components/organisms/BenefitSection';
import CaseStudySection from '../components/organisms/CaseStudySection';
import ProductPageHero from '../components/organisms/ProductPageHero';
import ROISection from '../components/organisms/ROISection';

const LocaliseForYourMarketsPage = ({ data: { page: data } }) => (
  <Layout>
    <Seo post={data} />

    <ProductPageHero
      backgroundImage=""
      {...data.localiseForYourMarkets.localiseForYourMarketsHero}
    />
    {data.localiseForYourMarkets.localiseForYourMarketsBenefits.length > 0 && (
      <BenefitSection
        imageSide="right"
        animationName="spirable-locations-templates"
        animationBackground="linear-gradient(142.22deg, #FFFFFF -35.72%, #EDF6FD -35.71%, #2A8FED 105.25%, #2A8FED 105.26%)"
        {...data.localiseForYourMarkets.localiseForYourMarketsBenefits[0]}
      />
    )}

    <ROISection
      {...{
        lead: data.localiseForYourMarkets.roiSection.lead,
        title: data.localiseForYourMarkets.roiSection.title,
        body: data.localiseForYourMarkets.roiSection.body,
        features: data.localiseForYourMarkets.roiSection.roiSectionFeatures,
      }}
    />

    {data.localiseForYourMarkets.localiseForYourMarketsBenefits.length > 1 && (
      <BenefitSection
        imageSide="left"
        {...data.localiseForYourMarkets.localiseForYourMarketsBenefits[1]}
      />
    )}
    <CaseStudySection
      {...data.localiseForYourMarkets.caseStudy}
      backgroundImage="linear-gradient(142.24deg, #BEDDFA 7.42%, #2A8FED 86.31%)"
    />
  </Layout>
);

export const pageQuery = graphql`
  query LocaliseForYourMarketsQuery($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      slug
      ...SEO
      localiseForYourMarkets {
        localiseForYourMarketsHero {
          title
          body
          image {
            ...Image
          }
        }
        localiseForYourMarketsBenefits {
          lead
          title
          body
          image {
            ...Image
          }
        }
        roiSection {
          lead
          title
          body
          roiSectionFeatures {
            title
            image {
              ...Image
            }
          }
        }
        caseStudy {
          logo {
            ...Image
          }
          title
          body
          cta {
            label
            to
          }
          quote
          author {
            name
            title
            photo {
              ...Image
            }
          }
        }
      }
    }
  }
`;

export default LocaliseForYourMarketsPage;
