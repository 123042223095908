import React from 'react';

interface ROISectionProps {
  lead: string;
  title: string;
  body: string;
  features: {
    title: string;
    image: {
      sourceUrl: string;
      altText: string;
    };
  }[];
}

const ROISection: React.FC<ROISectionProps> = ({
  lead,
  title,
  body,
  features,
}) => (
  <section
    className="py-16 bg-cover md:py-32"
    style={{
      backgroundImage:
        'linear-gradient(253.2deg, #64ACF4 1.09%, #82C4DD 99.28%)',
    }}
  >
    <div className="u-container">
      <div className="max-w-2xl mx-auto mb-16 text-center">
        <div className="text-white u-s1 opacity-30">{lead}</div>
        <h2 className="text-white u-h2">{title}</h2>
        <p className="text-lg leading-8 text-tertiary">{body}</p>
      </div>

      <div className="flex flex-col justify-between space-y-8 lg:space-y-0 lg:space-x-8 lg:flex-row xl:space-x-16">
        {features.map((feature, index) => (
          <>
            <div key={index} className="flex-shrink-0 text-center">
              <img
                src={feature.image.sourceUrl}
                alt={feature.image.sourceUrl}
                className="w-auto mx-auto mb-12 h-60"
              />
              <h3 className="text-white u-h6">{feature.title}</h3>
            </div>
            {index < features.length - 1 && (
              <>
                <div
                  className="relative flex-shrink-0 hidden w-5 h-32 bg-center bg-no-repeat lg:block top-14 bg-container"
                  style={{
                    backgroundImage: 'url(/images/efficiency-divider-v.svg)',
                  }}
                />
                <div
                  className="flex-shrink-0 w-64 h-8 mx-auto bg-center bg-no-repeat lg:hidden bg-container"
                  style={{
                    backgroundImage: 'url(/images/efficiency-divider-h.svg)',
                  }}
                />
              </>
            )}
          </>
        ))}
      </div>
    </div>
  </section>
);

export default ROISection;
